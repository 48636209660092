import React from "react"
import { graphql } from "gatsby"
import { HomeFeature } from "../components/Home"
import { CardContainer, CommonCard } from "../components/commonFeatureComp"
import PopularIntegrations from "../components/popularIntegrations"
import BgImgComp from "../components/bgImgComp"
import SEO from "../components/SEO/seo"
import { GatsbyImage } from "gatsby-plugin-image";

export default ({ data, location }) => (
  <>
    <SEO
      title="Integration | Connect with the best email marketing autoresponders"
      description="Integrations with different email marketing autoresponders with Groupboss helps you to collect the leads in a single click."
      pathname={location.pathname}
      image={<GatsbyImage image={data.header_image.childImageSharp.gatsbyImageData} />}
    />
    <HomeFeature
      homeH1="Integrate your favourite tools with Groupboss"
      homeText="Generate Unlimited Leads, Send Them Emails And Manage Your All facebook Group Members With In-depth Data In a Single Place."
      image={<GatsbyImage image={data.header_image.childImageSharp.gatsbyImageData} />}
      emailButton=""
      homeSectionGrid="40% 38%"
      homeSectionJustify="end"
      columnGap="13.2%"
    />
    <PopularIntegrations />
    <CardContainer>
      <CommonCard
        cardTextWeight="500"
        cardText="24/7 Support by real people"
        cardPadding="13px 25px"
      />
      <CommonCard
        cardTextWeight="500"
        cardText="100% Secure payment"
        cardPadding="13px 25px"
      />
      <CommonCard
        cardTextWeight="500"
        cardText="Best customer experience"
        cardPadding="13px 25px"
      />
    </CardContainer>
    <BgImgComp />
  </>
)

export const query = graphql`{
  header_image: file(relativePath: {eq: "header_image.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
}
`
